import React, {Component} from "react";
import {AppContext, AppContextType} from "../AppContext";
import  './Footer.css';
import firebase from "firebase";
import {goTo} from "../Utils";
import {ImagesSlider} from "../ui/images-slider/ImagesSlider";
import {Header} from "../components/header/Header";

type Props = {
    data?: any;
};

type State = {
    footer:any;
};

class Footer extends Component<Props, State>{

    constructor(props: Props) {
        super(props);

    }

    componentDidMount() {

        const ctx = (this.context as AppContextType).appContext;

        const dbRef = firebase.database().ref(ctx.basePath + '/footer');
        dbRef.on('value', snapshot => {
            this.setState({footer: snapshot.val()})
        });
    }
    
    render(){

        {/* FOOTER */}
        return <div id={'footer-container'}>

            <Header title={this.state?.footer?.sezione_contattaci?.titolo_sezione_contattaci?._value}
                    labelButton={this.state?.footer?.sezione_contattaci?.pulsante_sezione_contattaci?.etichetta_pulsante_sezione_contattaci?._value}
                    linkButton={this.state?.footer?.sezione_contattaci?.pulsante_sezione_contattaci?.link_pulsante_sezione_contattaci?._value}></Header>

            {this.state?.footer?.sezione_contattaci?.gallery_sezione_contattaci?._list &&
            Object.keys(this.state?.footer?.sezione_contattaci?.gallery_sezione_contattaci?._list).length > 0 ?
            <div id={'imgCustomer'} className={'img-container-div'}>
                    <ImagesSlider autoplay={true} images={this.state?.footer?.sezione_contattaci?.gallery_sezione_contattaci?._list}></ImagesSlider>
            </div>
            : null
            }


            {/* SEZIONE CONTATTI */}
            <footer id={'contacts'}>
                <div className="padded">
                    <div className="background kreosoft-logo">
                        {" "}
                        <a
                            className="a-kreo"
                            href="https://www.kreosoft.com"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {" "}
                        </a>
                    </div>

                    <div className="section-footer-container client-footer-container">
                        <div className="section-footer-column">
                            <a
                                href={
                                    "mailto:" + this.state?.footer?.sezione_contatti?.email?._value
                                }
                            >
                                <p className={'font-16'}>{this.state?.footer?.sezione_contatti?.email?._value}</p>
                            </a>
                            <a
                                href={
                                    "tel:" + this.state?.footer?.sezione_contatti?.telefono?._value
                                }
                            >
                                <p className={'font-16'}>{this.state?.footer?.sezione_contatti?.telefono?._value}</p>
                            </a>
                            <br></br>
                            <br></br>
                            <p className={'font-16'}>{this.state?.footer?.sezione_contatti?.indirizzo?.linea_1?._value}</p>
                            <p className={'font-16'}>{this.state?.footer?.sezione_contatti?.indirizzo?.linea_2?._value}</p>
                            <p className={'font-16'}>{this.state?.footer?.sezione_contatti?.indirizzo?.linea_3?._value}</p>
                            <br></br>
                            <br></br>
                        </div>
                        <div className="section-footer-column">
                            <div className="section-footer-container client-footer-container hide-mobile">
                                <div className="section-footer-column">
                                </div>
                                <div className="section-footer-column">
                                </div>
                            </div>
                        </div>
                        <div className="flex-05 section-footer-column ">
                            <p className={'font-16'}>
                                <b>Social</b>
                            </p>
                            {Object.keys(this.state?.footer?.sezione_contatti?.social?._list||{}).
                            filter((key:string)=> this.state?.footer?.sezione_contatti?.social?._list[key]?._id!=0)?.
                            map((key:string)=>this.state?.footer?.sezione_contatti?.social?._list[key])?.
                            sort((a:any, b:any)=>a?._order > b?._order? 1:-1).
                            map((item: any, index:number) => {
                                return (
                                    <p className={'font-16'} key={'itm' + index}>
                                        <a
                                            href={item?.link._value}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {item?.descrizione?._value}
                                        </a>
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                    <div className="privacy-box">
                        <p className={'font-16'}>
                            <span>
                            © Kreosoft
                            </span>
                        </p>
                        <p className={'hide-mobile'}></p>
                        <p className={'font-12'}>
                        <a
                            href="https://www.kreosoft.com/Views/privacy-policy.php"
                            rel="noreferrer"
                            target="_blank"
                        >
                            privacy & cookie policy
                        </a>
                        </p>
                    </div>
                </div>
            </footer>
        </div>
  
    }
}

Footer.contextType = AppContext;
export default Footer;
