import { Component } from "react";
import "./Splashscreen.css";

type Props = {
  loaded: boolean
};

type State = {
  hide: boolean
}

export default class Splashscreen extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      !this.state?.hide? (<div className={"splash-container " + (this.props.loaded? "animate__animated animate__fadeOut animate__faster" :"")}>
        <div className="splash-loading">
            <div className="splash-img background"> </div>

        </div>
      </div>
    ) : <></>);
  }

  componentWillReceiveProps(props:{loaded:boolean}){
    if(props && props.loaded){
      setTimeout(()=>{
        this.setState({hide : true});
      },250);
    }
}

}
