import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './typography.css';
import './base.css';
import './assets/themes/theme.scss';
import './assets/fonts/icons/style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AppContext} from "./AppContext";

let search = window.location.search;
let params = new URLSearchParams(search);
const draft =  params.get('draft')==="true";
const basePath=(draft ? 'drafts' : 'projects') + "/4guest_sit";

ReactDOM.render(
  <AppContext.Provider value={ {appContext:{basePath:basePath} } }>
    <App />
  </AppContext.Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
