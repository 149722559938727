import React from "react";
import logoAsset from '../assets/images/4guest_logo.gif';
import {Link, NavLink} from "react-router-dom";
import './Toolbar.css';
import {AppContext, AppContextType} from "../AppContext";
import firebase from "firebase/app";

class ToolbarProps {
}

class ToolbarState {
    mobileMenuOpened = false;
    logo?: string;
    menuInToolbar: any[] = [];
    pulsantiPrimari: any[] = [];
    pulsantiSecondari: any[] = [];
}


export class Toolbar extends React.Component<ToolbarProps, ToolbarState> {


    constructor(props: any) {
        super(props);

        this.state = {
            mobileMenuOpened: false,
            menuInToolbar: [],
            pulsantiPrimari: [],
            pulsantiSecondari: []
        }
    }


    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;

        this.getMenuLabels(ctx);
        this.getPulsantiToolbar(ctx)
    }

    getMenuLabels(ctx: any) {
        firebase.database().ref(ctx.basePath + '/impostazioni/etichette/menu').get().then(async res => {
                const val = res.val();
                let menu: any[] = []
                if (val) {
                    const menuKeys = Object.keys(val).filter((key: string) => !key.startsWith('_')).sort((a, b) => (val[a]?._order > val[b]?._order) ? 1 : -1);

                    for (let menuKey of menuKeys) {
                        let isVisible = await firebase.database().ref(ctx.basePath + '/pagine/' + menuKey + '/_visibility').get();

                        if (isVisible.val()) {
                            menu.push({
                                description: val[menuKey]?.['_value'],
                                url: val[menuKey]?._url
                            })
                        }
                    }
                }
                this.setState({
                    menuInToolbar: menu,
                    logo: logoAsset
                })
            }
        );
    }

    getPulsantiToolbar(ctx: any) {
        firebase.database().ref(ctx.basePath + '/impostazioni/pulsanti_toolbar').get().then(async res => {
                const val = res.val();
                const pulsantiPrimari = (Object.keys(val?.primari?._list) && Object.keys(val?.primari?._list)?.length > 1) ? (Object.keys(val?.primari?._list).map(key => val?.primari?._list[key]).filter(item => item._id != 0)) : [];
                const pulsantiSecondari = (Object.keys(val?.secondari?._list) && Object.keys(val?.secondari?._list)?.length > 1) ? (Object.keys(val?.secondari?._list).map(key => val?.secondari?._list[key]).filter(item => item._id != 0)) : [];

                this.setState({
                    pulsantiPrimari: pulsantiPrimari,
                    pulsantiSecondari: pulsantiSecondari,
                })
            }
        );
    }


    toggleMenu() {
        let isMenuOpened = this.state.mobileMenuOpened;
        this.setState({
            mobileMenuOpened: !isMenuOpened
        })
    }

    render() {

        return <>
            <div className={'toolbar'}>

                <div className={'logo-div padded'}>
                    <Link key='home-nav' to=''>
                        {
                            this.state?.logo ?
                                <img data-aos="fade"
                                     data-aos-once="true" className="logo-img" src={this.state.logo}/>
                                : null
                        }

                    </Link>

                    <div className={'flex gap_16px align-items_center'}>
                        {
                            this.state?.pulsantiPrimari ?
                                <div id={'primary-btns-container'} className={'flex gap_16px'}>
                                    {this.state?.pulsantiPrimari?.map(item => {
                                            return <button className={'btn'}>
                                                <a href={item?.link?._value}
                                                   target={item?.link?._value && item?.link?._value?.startsWith('http') ? '_blank' : '_self'}>
                                                    {item?.icona?._value ?
                                                        <i className={item?.icona?._value}> </i> : null
                                                    }

                                                    <p className={'font-14-500 hide-mobile'}>{item?.etichetta?._value}</p>
                                                </a>
                                            </button>
                                        }
                                    )}
                                </div>


                                : null
                        }

                        <i className={"icon icon-menu"} onClick={() => this.toggleMenu()}></i>
                    </div>
                </div>
                <div className={'menu-div hide-mobile padded'}>
                    <div className={'flex gap_40px'}>
                        {
                            this.state?.menuInToolbar?.map((menu, index) =>
                                <NavLink data-aos="fade"
                                         data-aos-once="true" key={'menu' + index}
                                         className={({isActive}) => (isActive ? "menu-voice-div menu-item-selected font-12" : "menu-voice-div font-12")}
                                         to={menu.url}>
                                    {menu.description}
                                </NavLink>
                            )
                        }
                    </div>

                    <div className={'flex gap_40px'}>
                        {
                            this.state?.pulsantiSecondari ?
                                <div id={'secondary-btns-container'} className={'flex gap_16px'}>
                                    {this.state?.pulsantiSecondari?.map(item => {
                                            return <a href={item?.link?._value}
                                                      target={item?.link?._value && item?.link?._value?.startsWith('http') ? '_blank' : '_self'}>
                                                <button className={'btn'}>

                                                    {item?.icona?._value ?
                                                        <i className={item?.icona?._value}> </i> : null
                                                    }

                                                    <p className={'font-14-500 hide-mobile'}>{item?.etichetta?._value}</p>

                                                </button>
                                            </a>
                                        }
                                    )}
                                </div>


                                : null
                        }
                    </div>
                </div>
            </div>


            <div className={'mobile-menu  ' + (this.state.mobileMenuOpened ? 'mobile-menu-opened' : '')}>

                {
                    this.state?.menuInToolbar?.map((menu, index) =>
                        <NavLink key={'menumobile-' + index}
                                 className={({isActive}) => (isActive ? "mobile-menu-voice-div menu-item-selected font-32-500" : "mobile-menu-voice-div font-32-500")}
                                 onClick={() => this.toggleMenu()} to={menu.url}>
                            {menu.description}
                        </NavLink>
                    )
                }

                {this.state?.pulsantiSecondari?.map((item, index) => {
                        return <a key={'menumobile-s-' + index}
                                  className={"mobile-menu-voice-div font-32-500"}
                                  onClick={() => this.toggleMenu()} href={item.link?._value}>
                            {item?.etichetta?._value}
                        </a>
                    }
                )}

            </div>
        </>
    }
}

Toolbar.contextType = AppContext;

