import React, { Component } from "react";
import "./Management.css";
import {AppContext, AppContextType} from "../../AppContext";
import {BulletList} from "../../components/bullet-list/BulletList";
import firebase from "firebase";
import {ScreenshotsGallery} from "../../components/screenshots-gallery/ScreenshotsGallery";
import {NewsGrid} from "../../components/news-grid/NewsGrid";
import {Helmet} from "react-helmet";

type Props = {
};

type State = {
  gestionale:any;
};

class Management extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    const ctx = (this.context as AppContextType).appContext;

    const dbRef = firebase.database().ref(ctx.basePath + '/pagine/gestionale');
    dbRef.on('value', snapshot => {
      this.setState({gestionale: snapshot.val()})
    });
  }

  render() {
    return (
     <div id={'gestionale-page'}>

         {/* SEO */}
         <Helmet>
             {this.state?.gestionale?.seo?.meta_titolo?._value ?
                 <title>{this.state?.gestionale?.seo?.meta_titolo?._value + " - " + process.env.REACT_APP_TITLE}</title> :
                 <title>{process.env.REACT_APP_TITLE}</title>}
             <meta name='description' content={this.state?.gestionale?.seo?.meta_descrizione?._value as string}/>
             <meta name='keywords' content={this.state?.gestionale?.seo?.meta_keywords?._value}/>
         </Helmet>

       {/* Header Gestionale */}
       <div className={'bullet-lists'}
            data-aos="fade-in"
            data-aos-once="true">
         {this.state?.gestionale?.header_gestionale && this.state?.gestionale?.header_gestionale?._list &&
         Object.keys(this.state?.gestionale?.header_gestionale._list).length > 0 ?
             Object.keys(this.state?.gestionale?.header_gestionale._list)
                 ?.filter(key => this.state?.gestionale?.header_gestionale._list[key]?._id != 0)
                 ?.map(key => this.state?.gestionale?.header_gestionale._list[key])
                 ?.sort((a: any, b: any) => a?._order > b?._order ? 1 : -1)
                 .map((item: any, index: number) => {
                   return <BulletList title={item?.titolo?._value}
                                      description={item?.descrizione?._value}
                                      bulletList={item?.elenco_puntato?._list}
                                      gallery={item?.gallery?._list}></BulletList>
                 })
             : null
         }
       </div>

       {/* Galleries Gestionale */}
       <div className={'galleries'}>
         {this.state?.gestionale?.galleries_gestionale && this.state?.gestionale?.galleries_gestionale?._list &&
         Object.keys(this.state?.gestionale?.galleries_gestionale._list).length > 0 ?
             Object.keys(this.state?.gestionale?.galleries_gestionale._list)
                 ?.filter(key => this.state?.gestionale?.galleries_gestionale._list[key]?._id != 0)
                 ?.map(key => this.state?.gestionale?.galleries_gestionale._list[key])
                 ?.sort((a: any, b: any) => a?._order > b?._order ? 1 : -1)
                 .map((item: any, index: number) => {
                   return <ScreenshotsGallery title={item?.titolo_gallery?._value}
                                      description={item?.descrizione_gallery?._value}
                                              headerButton={
                                                {
                                                  etichetta: item?.pulsante?.etichetta?._value,
                                                  link: item?.pulsante?.link?._value
                                                }
                                              }
                                      gallery={item?.gallery_immagini?._list}></ScreenshotsGallery>
                 })
             : null
         }
       </div>


         {/* Liste elenco puntato */}
         <div id={'lists-container-home'}>
             {this.state?.gestionale?.liste_elenco_puntato && this.state?.gestionale?.liste_elenco_puntato?._list &&
             Object.keys(this.state?.gestionale?.liste_elenco_puntato._list).length > 0 ?
                 Object.keys(this.state?.gestionale?.liste_elenco_puntato._list)
                     ?.filter(key => this.state?.gestionale?.liste_elenco_puntato._list[key]?._id != 0)
                     ?.map(key => this.state?.gestionale?.liste_elenco_puntato._list[key])
                     ?.sort((a: any, b: any) => a?._order > b?._order ? 1 : -1)
                     .map((item: any, index: number) => {
                         return <BulletList title={item?.titolo?._value}
                                            description={item?.descrizione?._value}
                                            headerButton={
                                                {
                                                    etichetta: item?.pulsante?.etichetta?._value,
                                                    link: item?.pulsante?.link?._value
                                                }
                                            }
                                            bulletList={item?.elenco_puntato?._list}
                                            gallery={item?.gallery?._list}></BulletList>
                     })
                 : null
             }
         </div>


         {/* News */}
         <NewsGrid title={this.state?.gestionale?.news?.titolo_news?._value}
                      headerButton={{
                          etichetta:this.state?.gestionale?.news?.pulsante?.etichetta?._value,
                          link: this.state?.gestionale?.news?.pulsante?.link?._value
                      }}
                      gallery={this.state?.gestionale?.news?.carosello_news?._list}/>
     </div>
    );
  }


}

Management.contextType = AppContext;
export default Management;
