import * as React from 'react';
import {HashRouter, Route, Routes} from "react-router-dom";
import {Toolbar} from "./toolbar/Toolbar";
import Home from "./Home";

import Management from "./menu/management/Management";

import News from "./news/News";
import NewsDetail from "./news/news-detail/News-Detail";
import AppPage from "./menu/app/AppPage";
import ESign from "./menu/e-sign/ESign";
import Website from "./menu/website/Website";
import ScrollToTop from "./config/scroll-to-top/ScrollToTop";
import Footer from "./footer/Footer";
import GetInTouch from "./menu/get-in-touch/GetInTouch";




const Main = () => {


    return (
        <>
            <HashRouter>
                <div>
                    <Toolbar></Toolbar>

                    <ScrollToTop />

                    <Routes >
                        <Route path='*' element={<Home />} />
                        <Route path="/" element={<Home />} />

                        <Route path="/gestionale" element={<Management />} />

                        <Route path="/app" element={<AppPage />} />

                        <Route path="/sito-web" element={<Website />} />

                        <Route path="/firma-elettronica" element={<ESign />} />

                        <Route path="/news" element={<News />} />
                        <Route path="/news/:key" element={<NewsDetail />} />

                        <Route path="/contatti" element={<GetInTouch />} />

                    </Routes>

                    <Footer> </Footer>


                </div>
            </HashRouter>
        </>

    );
};



export default Main
