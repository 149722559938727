
import firebase from "./config/firebase";

//const db = firebase.ref("/drafts/4guest");
const db = firebase.ref("/projects/4guest_sit");

class FirebaseService {
    getAll() {
        return db;
    }
}

export default new FirebaseService();
