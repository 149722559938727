import React, {Component} from "react";
import "./ESign.css";
import {AppContext, AppContextType} from "../../AppContext";
import {BulletList} from "../../components/bullet-list/BulletList";
import firebase from "firebase";
import {ScreenshotsGallery} from "../../components/screenshots-gallery/ScreenshotsGallery";
import {NewsGrid} from "../../components/news-grid/NewsGrid";
import {Helmet} from "react-helmet";

type Props = {};

type State = {
    firma_elettronica: any;
};

class ESign extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;

        const dbRef = firebase.database().ref(ctx.basePath + '/pagine/firma_elettronica');
        dbRef.on('value', snapshot => {
            this.setState({firma_elettronica: snapshot.val()})
        });
    }

    render() {
        return (
            <div id={'firma-elettronica-page'}>

                {/* SEO */}
                <Helmet>
                    {this.state?.firma_elettronica?.seo?.meta_titolo?._value ?
                        <title>{this.state?.firma_elettronica?.seo?.meta_titolo?._value + " - " + process.env.REACT_APP_TITLE}</title> :
                        <title>{process.env.REACT_APP_TITLE}</title>}
                    <meta name='description' content={this.state?.firma_elettronica?.seo?.meta_descrizione?._value as string}/>
                    <meta name='keywords' content={this.state?.firma_elettronica?.seo?.meta_keywords?._value}/>
                </Helmet>

                {/* Header Firma Elettronica */}
                <div className={'bullet-lists'}
                     data-aos="fade-in"
                     data-aos-once="true">
                    {this.state?.firma_elettronica?.header_firma_elettronica && this.state?.firma_elettronica?.header_firma_elettronica?._list &&
                    Object.keys(this.state?.firma_elettronica?.header_firma_elettronica._list).length > 0 ?
                        Object.keys(this.state?.firma_elettronica?.header_firma_elettronica._list)
                            ?.filter(key => this.state?.firma_elettronica?.header_firma_elettronica._list[key]?._id != 0)
                            ?.map(key => this.state?.firma_elettronica?.header_firma_elettronica._list[key])
                            ?.sort((a: any, b: any) => a?._order > b?._order ? 1 : -1)
                            .map((item: any, index: number) => {
                                return <BulletList title={item?.titolo?._value}
                                                   description={item?.descrizione?._value}
                                                   bulletList={item?.elenco_puntato?._list}
                                                   gallery={item?.gallery?._list}></BulletList>
                            })
                        : null
                    }
                </div>

                {/* Galleries Firma Elettronica */}
                <div className={'galleries'}>
                    {this.state?.firma_elettronica?.galleries_app && this.state?.firma_elettronica?.galleries_app?._list &&
                    Object.keys(this.state?.firma_elettronica?.galleries_app._list).length > 0 ?
                        Object.keys(this.state?.firma_elettronica?.galleries_app._list)
                            ?.filter(key => this.state?.firma_elettronica?.galleries_app._list[key]?._id != 0)
                            ?.map(key => this.state?.firma_elettronica?.galleries_app._list[key])
                            ?.sort((a: any, b: any) => a?._order > b?._order ? 1 : -1)
                            .map((item: any, index: number) => {
                                return <ScreenshotsGallery title={item?.titolo_gallery?._value}
                                                           description={item?.descrizione_gallery?._value}
                                                           headerButton={
                                                               {
                                                                   etichetta: item?.pulsante?.etichetta?._value,
                                                                   link: item?.pulsante?.link?._value
                                                               }
                                                           }
                                                           gallery={item?.gallery_immagini?._list}></ScreenshotsGallery>
                            })
                        : null
                    }
                </div>

                {/* Firma - Liste elenco puntato */}
                <div id={'lists-container-home'}>
                    {this.state?.firma_elettronica?.liste_elenco_puntato && this.state?.firma_elettronica?.liste_elenco_puntato?._list &&
                    Object.keys(this.state?.firma_elettronica?.liste_elenco_puntato._list).length > 0 ?
                        Object.keys(this.state?.firma_elettronica?.liste_elenco_puntato._list)
                            ?.filter(key => this.state?.firma_elettronica?.liste_elenco_puntato._list[key]?._id != 0)
                            ?.map(key => this.state?.firma_elettronica?.liste_elenco_puntato._list[key])
                            ?.sort((a: any, b: any) => a?._order > b?._order ? 1 : -1)
                            .map((item: any, index: number) => {
                                return <BulletList title={item?.titolo?._value}
                                                   description={item?.descrizione?._value}
                                                   headerButton={
                                                       {
                                                           etichetta: item?.pulsante?.etichetta?._value,
                                                           link: item?.pulsante?.link?._value
                                                       }
                                                   }
                                                   bulletList={item?.elenco_puntato?._list}
                                                   gallery={item?.gallery?._list}
                                                   key={'bl1' + index}></BulletList>
                            })
                        : null
                    }
                </div>


                {/* Firma - News */}
                <NewsGrid title={this.state?.firma_elettronica?.news?.titolo_news?._value}
                             headerButton={{
                                 etichetta:this.state?.firma_elettronica?.news?.pulsante?.etichetta?._value,
                                 link: this.state?.firma_elettronica?.news?.pulsante?.link?._value
                             }}
                             gallery={this.state?.firma_elettronica?.news?.carosello_news?._list}/>
            </div>
        );
    }


}

ESign.contextType = AppContext;
export default ESign;
