import React from 'react';
import './App.css';
import FirebaseService from "./firebase.service";
import firebase from "firebase/app";
// @ts-ignore
import AOS from 'aos';
import "aos/dist/aos.css";
import Splashscreen from './splashscreen/Splashscreen';
import Main from "./Main";
// @ts-ignore
import DOMPurify from 'dompurify';
import {AppContext, AppContextType} from "./AppContext";

interface AppState {
    data: {},
    loaded: boolean;

    theme?: string;
    style?: string;
}

class App extends React.Component<{}, AppState> {

    constructor(props: any) {
        super(props);

        AOS.init({
            // initialise with other settings
            duration: 1000
        });
    }


    componentDidMount() {
        FirebaseService.getAll().on("value", this.onDataChange.bind(this));

        let ctx = (this.context as AppContextType).appContext;

        firebase
            .database()
            .ref(ctx.basePath + "/impostazioni/css")
            .on("value",
                (res) => {
                    if(res.val()){
                        const css = res.val();
                        this.setState({
                            style: css.style?._value,
                            theme: css.theme?._value
                        })
                    }

                });
    }


    render() {
        return (
            <>
                <head>
                    <style dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(this.state?.theme)
                    }}>

                    </style>
                    <style dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(this.state?.style)
                    }}>
                    </style>
                </head>
                <div className="App">

                    <Splashscreen loaded={this.state?.loaded}></Splashscreen>
                    <Main></Main>

                </div>
            </>
        );
    }

    componentWillUnmount() {
        FirebaseService.getAll().off("value", this.onDataChange.bind(this));
    }

    onDataChange(items: firebase.database.DataSnapshot) {
        let content = items.val();
        this.setState({
            data: content
        });

        setTimeout(() => {
            this.setState({
                loaded: true
            })
        }, 2000);
    }

}

export default App;
App.contextType = AppContext;
